import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Calorie Counter App Template | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/calorie-counter-mobile-app/"
    metaDescription="Design your own calorie-counting app with our premade UI template. Use the template as it is or customize it with our drag-and-drop editor."
    description="
    h2:Streamline your design with our calorie-counting app design template for mobile
    <br/>
    Bring your calorie counter app design to life with our premade <a:https://uizard.io/templates/>UI template</a>. Our templates come packed with everything you could possibly need to get your project off the ground including all the core screens of your design as well as all the core UI elements including CTAs, image blocks, and typography.
    <br/>
    h3:App design made easy with Uizard
    <br/>
    Whether you are a seasoned project manager or a founder setting up your start up, Uizard is here to support you from wireframe through to prototype. Our <a:https://uizard.io/ai-design/>AI design</a> features allow you to upload hand-drawn wireframes and digitize them, update text elements to optimize your CTAs, or even convert screenshots of other apps into workable mockups. Bringing your calorie counter app to life has never been so easy.
    <br/>
    h3:Collaborate rapidly in real-time
    <br/>
    Working as part of your team on your calorie app UI mockup? Uizard is designed for easy, rapid collaboration. Simply invite your team via email or share your project link for feedback. You can even share previews of your project with external stakeholders to get sign-off on your calorie app design.
    "
    pages={[
      "App landing page screen with user onboarding mockup",
      "Meal plan mockup screen with intuitive CTAs to recipe pages",
      "Recipe landing pages with recipe and calorie information",
      "Statistics and meal log screen mockups to demonstrate the core features of your app",
    ]}
    projectCode="xV7zdzPx3GfEX3YW1YgA"
    img1={data.image1.childImageSharp}
    img1alt="calorie counter mobile app design template overview"
    img2={data.image2.childImageSharp}
    img2alt="calorie counter mobile app design template meal plan screen"
    img3={data.image3.childImageSharp}
    img3alt="calorie counter mobile app design template onboarding screen"
    img4={data.image4.childImageSharp}
    img4alt="calorie counter mobile app design template statistics screen"
    img5={data.image5.childImageSharp}
    img5alt="calorie counter mobile app design template summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/calorie-counter-mobile-app/calorie-counter-mobile-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/calorie-counter-mobile-app/calorie-counter-mobile-mealplan.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/calorie-counter-mobile-app/calorie-counter-mobile-onboarding.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/calorie-counter-mobile-app/calorie-counter-mobile-statistics.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/calorie-counter-mobile-app/calorie-counter-mobile-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
